import httpClient from "../http-client";

const ENDPOINT = "/paymentez";

export interface PaymentezTransacctionDto {
  id: number;
}

export interface PaymentezTransacctionCreatedDto {
  id: number;
}

class PaymentezService {
  getTransacciones(): Promise<PaymentezTransacctionCreatedDto[]> {
    return httpClient.get(`${ENDPOINT}`).then(response => {
      if (response.data && response.data) {
        return response.data;
      }
    });
  }

  getTransaction(transactionId: string) {
    return httpClient
      .get(`${ENDPOINT}/transaction/${transactionId}`)
      .then(response => {
        return response.data;
      });
  }

  accionTransaction(id: any, dto: any) {
    return httpClient
      .post(`${ENDPOINT}/transaction/accion/${id}`, dto)
      .then(response => {
        return response.data;
      });
  }
}

export default new PaymentezService();
