

















































import PaymentezAccion from "@/components/admin/paymentez/PaymentezAccion.vue";
import Vue from "vue";

import PaymentezService, {
  //PaymentezTransacctionCreatedDto,
  PaymentezTransacctionDto
} from "@/api/paymentez/PaymentezService";

export default Vue.extend({
  components: {
    PaymentezAccion
  },
  metaInfo: {
    title: "Paymentez Transacciones"
  },
  data() {
    return {
      dialog: false,
      dato: {
        id: 0
      } as PaymentezTransacctionDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Fecha", value: "Fecha" },
        { text: "Identificación", value: "pedido.cliente.identificacion" },
        { text: "Nombres", value: "pedido.cliente.displayNombreCompleto" },
        { text: "Monto", value: "pedido.displayTotal" },
        { text: "status", value: "displayStatus" },
        { text: "status_detail", value: "displayStatusDetail" },
        { text: "Transaction ID", value: "transactionId" },
        { text: "ref/pedido", value: "pedido.id" },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as PaymentezTransacctionDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        id: 0
      };
      this.dato = newDto;
    },
    editItem(item: PaymentezTransacctionDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      PaymentezService.getTransacciones()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Pendientes",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
