
















































































import Vue from "vue";
import DistanciaService from "@/api/DistanciaService";
import PedidoService, {
  PedidoCreatedDto,
  PedidoDto
} from "@/api/pedido/PedidoService";
export default Vue.extend({
  props: ["dialog", "dato", "modalidad"], // modalidad {rechazable: boolean, aprobable: boolean}
  data() {
    return {
      saving: false,
      accion: "",
      motivoRechazo: "",
      editedIndex: -1,
      editedItem: {
        id: 0,
        nombre: ""
      },
      papeleta: { fileName: "" }
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
    this.loadPapeleta();
  },
  methods: {
    save() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      PedidoService.accionPapeleta(this.editedItem.id, {
        accion: this.accion,
        motivoRechazo: this.motivoRechazo
      })
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Acción",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Su acción",
            text: "No se pudo guardar"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getPapeletaImageUrl(fileName: string) {
      return PedidoService.getPapeletaImageUrl(fileName);
    },
    loadPapeleta() {
      PedidoService.getPapeleta(this.editedItem.id)
        .then(response => {
          if (response) {
            this.papeleta = response;
          }
        })
        .catch(() => {
          console.log("no se pudo obtener la papeleta");
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    splitString(str: string, separator: string) {
      return str.split(separator);
    }
  },
  computed: {
    haveDetails() {
      const lista = (this.editedItem as any)?.detalles ?? [];
      let response = false;
      for (const item of lista) {
        const nombre = item?.articuloInventario?.nombre ?? null;
        if (nombre) {
          response = true;
          break;
        }
      }
      return response;
    },
    accionOptions() {
      const acciones = [];
      if (this.modalidad && this.modalidad.aprobable) {
        acciones.push({ text: "Aprobar", value: "1" });
      }
      if (this.modalidad && this.modalidad.rechazable) {
        acciones.push({ text: "Rechazar", value: "2" });
      }
      if (this.modalidad && this.modalidad.anulable) {
        acciones.push({ text: "Anular", value: "3" });
      }
      return acciones;
    }
  },
  watch: {}
});
