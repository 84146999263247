

















































import Vue from "vue";
import DistanciaService from "@/api/DistanciaService";
import PaymentezService from "@/api/paymentez/PaymentezService";
import PedidoService, {
  PedidoCreatedDto,
  PedidoDto
} from "@/api/pedido/PedidoService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      accion: "1",
      motivoRechazo: "",
      editedIndex: -1,
      editedItem: {
        id: 0,
        transactionId: ""
      },
      accionOptions: [{ text: "Reversar sin anular Pedido", value: "1" }],
      papeleta: { fileName: "" },
      transaction: {}
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
    //this.loadPaymentezTransaction();
  },
  methods: {
    save() {
      PaymentezService.accionTransaction(this.editedItem.id, {
        accion: this.accion,
        motivoRechazo: this.motivoRechazo
      })
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Acción",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Su acción",
            text: "No se pudo guardar"
          });
        });
    },
    getPapeletaImageUrl(fileName: string) {
      return PedidoService.getPapeletaImageUrl(fileName);
    },
    loadPaymentezTransaction() {
      PaymentezService.getTransaction(this.editedItem.transactionId)
        .then(response => {
          //
          this.transaction = response;
        })
        .catch(() => {
          //
        });
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    }
  },
  computed: {},
  watch: {}
});
